import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Renderer2} from '@angular/core';
import {DataHandlerService} from '../../service/data-handler.service';
import {User} from '../../model/user';
import {NotificationIn, NotificationInNew} from '../../model/notification-in';
import {ServerService} from '../../service/server.service';
import {DatePipe} from '@angular/common';
import {LeaveTeamComponent} from '../../dialog/leave-team/leave-team.component';
import {MatDialog} from '@angular/material/dialog';
import {YesNoComponent} from '../../dialog/yes-no/yes-no.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isShowMassage: boolean;
  isShortMenu: boolean;
  isMenuLeft: boolean;
  isShowProfile: boolean;
  elem: any;
  user: User;
  notifications: NotificationIn[];
  newNotifications: NotificationInNew[];

  @ViewChild('messageEl') messageEl: ElementRef;
  @ViewChild('emailEl') emailEl: ElementRef;
  @ViewChild('profilElement') profilElement: ElementRef;
  @ViewChild('profilElementIcon') profilElementIcon: ElementRef;
  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.messageEl.nativeElement.contains(event.target) && !this.emailEl.nativeElement.contains(event.target)) {
      if (this.isShowMassage) {
        this.render.removeClass(this.messageEl.nativeElement, 'show');
        this.isShowMassage = false;
      }
    }

    if (!this.profilElement.nativeElement.contains(event.target) && !this.profilElementIcon.nativeElement.contains(event.target)) {
      if (this.isShowProfile) {
        this.render.removeClass(this.profilElement.nativeElement, 'show');
        this.isShowProfile = false;
      }
    }
  }
  constructor(public dialog: MatDialog, private render: Renderer2, private dataHandler: DataHandlerService,
              private  srv: ServerService, private toast: ToastrService, public dateP: DatePipe) {
  }

  ngOnInit(): void {
    this.isShowMassage = false;
    this.isShortMenu = false;
    this.isMenuLeft = false;
    this.elem = document.documentElement;
    this.user = this.dataHandler.user;
    this.dataHandler.userSubject.subscribe(user => {
      this.user = user;
      this.srv.getNewNotificationByRecipient(this.user.id).subscribe(newNotif => {
          this.newNotifications = newNotif;
        }
      );
      this.srv.getNotificationByRecipient(this.user.id).subscribe(notif => {
          this.notifications = notif;
        }
      );
    });
  }

  switchPage(page: string): void{
    this.dataHandler.setPage(page);
  }

  clicked(): void {
    if (this.isShowMassage) {
      this.render.removeClass(this.messageEl.nativeElement, 'show');
      this.isShowMassage = false;
    } else {
      this.render.addClass(this.messageEl.nativeElement, 'show');
      this.isShowMassage = true;
    }
  }
  clickedMenu(): void {
    if (this.isShortMenu) {
      this.render.removeClass(document.body, 'sidebar-icon-only');
      this.isShortMenu = false;
    } else {
      this.render.addClass(document.body, 'sidebar-icon-only');
      this.isShortMenu = true;
    }
  }

  clickedProfile(): void {
    if (this.isShowProfile) {
      this.render.removeClass(this.profilElement.nativeElement, 'show');
      this.isShowProfile = false;
    } else {
      this.render.addClass(this.profilElement.nativeElement, 'show');
      this.isShowProfile = true;
    }
  }


  clickedMenuLeft(): void {
    if (this.isMenuLeft) {
      this.render.removeClass(document.getElementById('sidebar'), 'active');
      this.isMenuLeft = false;
    } else {
      this.render.addClass(document.getElementById('sidebar'), 'active');
      this.isMenuLeft = true;
    }
  }

  openFullscreen(): void {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  logout(): void {
    this.dataHandler.exitAuth();
  }

  openDialog(i: number): void {
    let notify: boolean;
    let message: string;
    if (this.newNotifications[i].type === 0) {
      notify = false;
      message = this.newNotifications[i].message + '. Прийняти?';
    }
    else {
      notify = true;
      message = this.newNotifications[i].message;
    }
    const dialogRef = this.dialog.open(YesNoComponent, { disableClose: true, data: {
        title: message,
        dataKey: {index: i, notif: notify, id: this.newNotifications[i].id, answer: false},
        notif: notify
      } });
    dialogRef.afterClosed().subscribe(result => {
      this.srv.updateReadNotification(result.dataKey.id).subscribe(resp => {
        this.srv.getNewNotificationByRecipient(this.user.id).subscribe(newNotif => {
            this.newNotifications = newNotif;
          }
        );
      });
      // @ts-ignore
      if (!result.notif &&  this.newNotifications[i].sysInfo){
        const jObj: any = JSON.parse(this.newNotifications[i].sysInfo);
        if (jObj.teamId){
          if (this.user.team){
            this.toast.error('Ви вже маєте команду!');
          }
          else {
            this.srv.getTeamById(jObj.teamId).subscribe(team => {
              if (team){
                this.user.team = team;
                this.dataHandler.teamSubject.next(this.user.team);
                this.srv.updateUser(this.user).subscribe(resp => {
                  this.toast.info('Ви вступили в команду "' + this.user.team.title + '"');
                  this.srv.deleteNotification(this.newNotifications[i].id).subscribe(respio => {
                    this.srv.getNewNotificationByRecipient(this.user.id).subscribe(newNotif => {
                        this.newNotifications = newNotif;
                      }
                    );
                  });
                });
              }
            });
          }
        }
      }
    });
  }

  changeNotificationContainer(): void{
    this.srv.getAllNotificationByRecipient(this.user.id).subscribe(notif => {
        this.newNotifications = notif;
      }
    );
  }

}
