import {User} from './user';


export class NotificationIn {
  id: number;
  userSender: User;
  userRecipient: User;
  read: boolean;
  deleted: boolean;
  type: number;
  message: string;
  date: number;
  sysInfo: string;

  constructor(id: number, userRecipient: User, userSender?: User,
              read?: boolean, deleted?: boolean, type?: number, message?: string, sysInfo?: string) {
    this.id      = id;
    this.userSender    = userSender;
    this.userRecipient = userRecipient;
    this.read    = read;
    this.deleted = deleted;
    this.type    = type;
    this.message = message;
    this.sysInfo = sysInfo;
    this.date = Date.now();
  }
}
export class NotificationInNew extends NotificationIn{
  senderName: string;
  senderLogo: string;
  constructor(id: number, userRecipient: User, senderName: string, senderLogo: string) {
    super(id, userRecipient);
    this.senderLogo = senderLogo;
    this.senderName = senderName;
  }
}
