<div class="container rounded bg-white mt-5">
  <div class="row">
    <div class="col-md-4 border-right">
      <div class="d-flex flex-column align-items-center text-center p-3 py-5"><img class="rounded-circle mt-5" src="{{user.logo}}" width="90"><span class="font-weight-bold">{{user.name}}</span><span class="text-black-50">{{user.email}}</span><span>{{user.city}}</span></div>
      <div class="form-group">
        <label for="file">Змінити іконку</label>
        <input type="file"
               id="file"
                 >
        </div>
        <div class="form-group">
          <label>File upload</label>
          <input type="file" name="img[]"  class="file-upload-default">
          <div class="input-group col-xs-12">
            <input type="text" class="form-control file-upload-info" disabled="" placeholder="Upload Image">
            <span class="input-group-append">
                              <button class="file-upload-browse btn btn-gradient-primary" (change)="handleFileInput($event.target.files)" type="button">Upload</button>
                            </span>
          </div>
        </div>
    </div>
    <div class="col-md-8">
      <div class="p-3 py-5">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <a (click)="dialogRef.close()"> <div class="d-flex flex-row align-items-center back"><i class="fa fa-long-arrow-left mr-1 mb-1"></i>
            <h6>Повернутись</h6>
          </div></a>
          <h6 class="text-right">Редагування Профілю</h6>
        </div>
        <div class="">
          <div class=""><input minlength="6" type="text"  class="form-control" placeholder="Ваше ім'я" [(ngModel)]="user.name" #name="ngModel"></div>
        </div>

          <div class="theme-red" *ngIf="name.errors?.minlength">
            Не менше 6 символів.
          </div>

        <div class="row mt-3">
          <div class="col-md-6"><input type="text" disabled class="form-control" placeholder="E-mail" value="{{user.email}}"></div>
          <div class="col-md-6"><input type="text" [(ngModel)]="user.telephone"  class="form-control"  placeholder="Номер телефону"></div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6"><input type="text" class="form-control" placeholder="Місцевість" [(ngModel)]="user.city"></div>
          <div class="col-md-6"><input type="text" class="form-control" placeholder="Парафія" [(ngModel)]="user.paroch"></div>
        </div>
        <div class="mt-3">
          <div class=""><input minlength="6" type="password" class="form-control" [(ngModel)]="user.password" placeholder="Пароль"></div>
        </div>
        <div class="mt-5 text-right"><button (click)="saveProfile()" class="btn btn-primary profile-button" type="button">Зберегти профіль</button></div>
      </div>
    </div>
  </div>
</div>
