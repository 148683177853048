import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {NgModel} from '@angular/forms';
import {ServerService} from '../../service/server.service';
import {DataHandlerService} from '../../service/data-handler.service';
import {ToastrService} from 'ngx-toastr';
import {ViewportScroller} from '@angular/common';
import {catchError} from 'rxjs/operators';
import {NotificationIn} from '../../model/notification-in';
import {User} from '../../model/user';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent implements OnInit, AfterViewInit  {
  user: User;
  @ViewChild('myEl') myEl: ElementRef;
  @ViewChild('titleE') titleE: NgModel;
  initiate: boolean;
  name: string;
  finish: boolean;
  email: string;
  constructor(private srv: ServerService, private dataHendler: DataHandlerService, @Inject(MAT_DIALOG_DATA) public data: any,
              // tslint:disable-next-line:max-line-length
              private toast: ToastrService, private scroll: ViewportScroller, private cd: ChangeDetectorRef, private dialogRef: MatDialogRef<AddMemberComponent>) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initiate = true;
    setTimeout(() => this.name = '');
    this.email = '';
    this.finish = false;
    this.user = this.data.dataKey;
    this.cd.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    // console.log(event);
    if (this.myEl.nativeElement.contains(event.target)) {
      // this.dialogRef.close();
    }

  }
  send(): void{
    if (this.email){
      this.srv.getUserByEmail(this.email).subscribe(resp => this.afterUserGet(resp), catchError(this.srv.handleError('addUser', [])));
    }
    else {
      this.srv.addTextUserToTeam(this.name, '', this.user.team).subscribe(respion => {
        if (!respion.error) {
          this.toast.success('Добавлено в команду, як звичайного гравця');
          this.dialogRef.close();
        }
        else {
          this.toast.error(respion.error.error);
        }
      });
    }
  }


  afterUserGet(resp: any): void {
    if (resp) {
      this.user.team.captain = null;
      const notif = new NotificationIn(0, resp, this.user);
      notif.message = 'Вас запрошено в команду "' + this.user.team.title.toString() + '" гравцем ' + this.user.name.toString();
      notif.sysInfo = JSON.stringify({teamId: this.user.team.id});
      this.toast.success(resp.name, 'Знайдено!');
      this.srv.addNotification(notif).subscribe(respN => this.afterNotificationAdd(respN), catchError(this.srv.handleError('addNot', [])));
    } else {
      this.srv.addTextUserToTeam(this.name, this.email, this.user.team).subscribe(respion => {
        if (!respion.error) {
          this.toast.success('Добавлено в команду, як звичайного гравця', 'Не знайдено зареєстрованого користувача!');
          this.dialogRef.close();
        }
        else {
          this.toast.error(respion.error.error);
        }
      });
    }
  }

  afterNotificationAdd(resp: any): void {
    this.user.team.captain = this.user;
    if (resp.status === 200) {
      this.toast.success('', 'Відіслано сповіщення!');
      this.dialogRef.close();
    } else {
      this.toast.error(resp.error.error);
    }
  }
}
