import {User} from './user';

export class Team {
  id: number;
  title: string;
  captain: User;
  image: string;
  city: string;
  about: string;
  paroch: string;
  constructor(id: number, title: string, captain: User, image?: string, city?: string, about?: string, paroch?: string) {
    this.id = id;
    this.title = title;
    this.captain = captain;
    this.city = city;
    this.about = about;
    this.paroch = paroch;
    this.image = image;
  }
}
