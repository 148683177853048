import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {DataHandlerService} from './data-handler.service';
import {User} from '../model/user';
import {Team} from '../model/team';

@Injectable({
  providedIn: 'root'
})
export class AuthIntercepService implements HttpInterceptor{

  // private srvUrl = 'http://192.168.137.1:3000/';
  private srvUrl = 'https://s1.youfra.com.ua/';

  private log(message: string): void {
    console.log(message);
  }

  handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      if (error.status === 401){
      }
      return of(error as T);
    };
  }

  constructor(private  http: HttpClient) {
  }


  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const idToken = localStorage.getItem('SESSIONID');

    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization',
          'Bearer ' + idToken)
      });

      return next.handle(cloned);
    }
    else {
      return next.handle(req);
    }
  }

  getId(): Observable<any> {
    return this.http.get(this.srvUrl + 'api/id').pipe(
      catchError(this.handleError('getId', []))
    );
  }

  getTeamById(id: string): Observable<any> {
    return this.http.get(this.srvUrl + 'api/teams/' + id).pipe(
      catchError(this.handleError('Get team by id', []))
    );
  }

  getOneUser(id: string): Observable<any> {
    return this.http.get(this.srvUrl + 'api/user/' + id).pipe(
      catchError(this.handleError('getOneUsers', []))
    );
  }
}
