import {User} from '../model/user';
import {Team} from '../model/team';
import {Question} from '../model/question';

export class TestData {
  static users: User[] = [{
    id: 0,
    name: 'Vasya',
    password: '2',
    email: 'test@gmail.com',
    team: null,
    city: ' ',
    telephone: ' ',
    logo: 'assets/img/faces/face1.jpg',
    paroch: ''
  },
    {
      id: 1,
      name: 'Serg',
      password: '2',
      email: 'test@gmail.com',
      team: null,
      city: ' ',
      telephone: ' ',
      logo: 'assets/img/faces/face2.jpg',
      paroch: ''
    },
    {
      id: 2,
      name: 'Bogdan',
      password: '2',
      email: 'test@gmail.com',
      team: null,
      city: ' ',
      telephone: ' ',
      logo: 'assets/img/faces/face3.jpg',
      paroch: ''
    },
  ];


  static teams: Team[] = [
    {id: 1, title: 'Dragons', captain: TestData.users[0], image: 'assets/img/faces/face4.jpg', city: 'Київ', about: '', paroch: ''},
    {id: 2, title: 'Armagedon', captain: TestData.users[1], image: 'assets/img/faces/face5.jpg', city: 'Вінниця', about: '', paroch: 'ММА'},
    {id: 3, title: 'White', captain: TestData.users[2], image: 'assets/img/faces/face2.jpg', city: 'Львів', about: '', paroch: ''},
  ];

  static questions: Question[] = [
    {id: 1, title: 'Why', answer: 'Becouse', description: 'What are you say?'},
    {id: 2, title: 'What', answer: 'Kampot', description: 'What are you say now?'},
    {id: 3, title: 'When', answer: 'Tomorow', description: 'What are you show?'},
  ];

}
