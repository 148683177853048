import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../model/user';
import {EnterValueComponent} from '../../dialog/enter-value/enter-value.component';
import {catchError} from 'rxjs/operators';
import {ServerService} from '../../service/server.service';
import {DataHandlerService} from '../../service/data-handler.service';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {EditProfileComponent} from '../../dialog/edit-profile/edit-profile.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @Input() user: User;
  constructor(private srv: ServerService, private dhs: DataHandlerService, public dialog: MatDialog, private toast: ToastrService) { }

  ngOnInit(): void {
  }

  editProfile(): void{
    const dialogRef = this.dialog.open(EditProfileComponent, { disableClose: true, data: {
        dataKey: this.user, title: 'Відредактуйте ваші дані та збережіть їх',
        value: '', valueName: 'E-mail', answer: false
      } });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.srv.updateUser(result).subscribe(resp1 => {});
        if (result.password) {
          this.srv.updateUserPass(result.id, result.password).subscribe(resp2 => {});
        }
      }
      this.dhs.initUser();
    });
  }
}
