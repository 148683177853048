<!-- partial:partials/_sidebar.html -->

<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">
    <li class="nav-item nav-profile">
      <a href="#"  (click)="switchPage('Team')" class="nav-link">
        <div class="nav-profile-image">
          <img src="{{user && user.team ? user.team.image : 'assets/img/no-team.png'}}" alt="profile">
          <span class="login-status busy"></span>
          <!--change to offline or busy as needed-->
        </div>
        <div class="nav-profile-text d-flex flex-column">
          <span class="font-weight-bold mb-2">{{user && user.team ? user.team.title : 'Без команди'}}</span>
          <span class="text-secondary text-small">{{user && user.team ? user.team.city : ''}}</span>
        </div>
        <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
      </a>
    </li>
    <li class="nav-item " [class.active]="page == 'Home'" (click)="switchPage('Home')">
      <a class="nav-link" href="#">
        <span class="menu-title">Головна</span>
        <i class="mdi mdi-home menu-icon"></i>
      </a>
    </li>
    <li class="nav-item" [class.active]="page == 'Statistic'" (click)="switchPage('Statistic')">
      <a class="nav-link" href="#">
        <span class="menu-title">Статистика</span>
        <i class="mdi mdi-chart-bar menu-icon"></i>
      </a>
    </li>
    <li class="nav-item" [class.active]="page == 'Question'" (click)="switchPage('Question')">
      <a class="nav-link" href="#">
        <span class="menu-title">Завдання</span>
        <i class="mdi mdi-book-open menu-icon"></i>
      </a>
    </li>
    <li class="nav-item sidebar-actions">
              <span class="nav-link">
                <div class="border-bottom">

                </div>
                <button id="checkQ"  (click)="openDialog()" class="btn btn-block btn-gradient-primary mt-4">+ Внести відповіді</button>

              </span>
    </li>
  </ul>
</nav>
<!-- partial -->
