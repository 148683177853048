<div #myEl>
  <h2 mat-dialog-title>Вкажи ім'я та e-mail адресу</h2>
  <mat-dialog-content>
    <form mat-dialog-content class="forms-sample">

      <div id="inputName" class="form-group">
        <label for="exampleinputName">Ім'я учасника:</label>
        <input type="text" [(ngModel)]="name"  name="name" minlength="3" class="form-control" id="exampleinputName" #titleE="ngModel" placeholder="Обов'язково">
        <label id="title-error" class="error mt-2 text-danger" for="exampleinputName" *ngIf="!titleE.valid && !titleE.untouched">Введіть ім'я не коротше 3 символів</label>
      </div>

      <div id="inputEmail" class="form-group">
        <label for="exampleinputEmail">E-mail учасника:</label>
        <input [(ngModel)]="email" name="email" type="text" class="form-control" id="exampleinputEmail" placeholder="Не обов'язково">
      </div>

    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button  (click)="send()" cdkFocusInitial type="submit" class="btn btn-gradient-primary mb-3 mr-2">Запросити</button>
    <button mat-dialog-close class="btn btn-gradient-info btn-light mb-3 mr-2">Закрити</button>
  </mat-dialog-actions>

</div>
