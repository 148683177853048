import {Component, Input, OnInit} from '@angular/core';
import {DataHandlerService} from '../../service/data-handler.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {User} from '../../model/user';

@Component({
  selector: 'app-main-panel',
  templateUrl: './main-panel.component.html',
  styleUrls: ['./main-panel.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('2s ease-out',
              style({height: 1000, opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({height: 1000, opacity: 1}),
            animate('2s ease-in',
              style({height: 0, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class MainPanelComponent implements OnInit {

  constructor(private dataHandler: DataHandlerService) {

  }

  isHome: boolean;
  page: string;
  @Input() user: User;

  ngOnInit(): void {
    this.dataHandler.pageSubject.subscribe(page => {
      this.switchPage(page);
      this.page = page;
    });
    this.page = 'Home';
  }

  switchPage(page: string): void {
    if (page === 'Home') {
      this.isHome = true;
    } else {
      this.isHome = false;
    }
  }
}
