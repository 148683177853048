<div #bodyElement >

<div [@inOutAnimation] class="container-scroller" *ngIf="isAuth">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">

    <app-navigator-bar [user]="user"></app-navigator-bar>
    <app-main-panel [user]="user"></app-main-panel>
  </div>
  <!-- page-body-wrapper ends -->
</div>
<div [@inOutAnimation] class="authorization" *ngIf="!isAuth && page !== 'registration'">
  <app-auth></app-auth>
</div>
<div [@inOutAnimation] class="registration" *ngIf="!isAuth && page === 'registration'">
  <app-registration></app-registration>
</div>



</div>
<!-- container-scroller -->
<!-- plugins:js -->

<!-- End custom js for this page -->
