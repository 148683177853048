import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavigatorBarComponent} from './views/navigator-bar/navigator-bar.component';
import {HeaderComponent} from './views/header/header.component';
import {FormsModule} from '@angular/forms';
import {QuestionsComponent} from './views/questions/questions.component';
import {MainPanelComponent} from './views/main-panel/main-panel.component';
import {InformationComponent} from './views/information/information.component';
import {AuthComponent} from './views/auth/auth.component';
import {AngularTiltModule} from 'angular-tilt';
import {RegistrationComponent} from './views/registration/registration.component';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import {MatRadioModule} from '@angular/material/radio';
import { RouterModule } from '@angular/router';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import {ToastrModule} from 'ngx-toastr';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ParamInterceptorModule} from './modules/param-interceptor/param-interceptor.module';
import { AnswerEnterComponent } from './dialog/answer-enter/answer-enter.component';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TeamComponent } from './views/team/team.component';
import { TeamCreateComponent } from './views/team-create/team-create.component';
import { AddMemberComponent } from './dialog/add-member/add-member.component';
import { LeaveTeamComponent } from './dialog/leave-team/leave-team.component';
import localeRu from '@angular/common/locales/ru';
import { YesNoComponent } from './dialog/yes-no/yes-no.component';
import { RememberPassComponent } from './dialog/remember-pass/remember-pass.component';
import { EnterValueComponent } from './dialog/enter-value/enter-value.component';
import { ProfileComponent } from './views/profile/profile.component';
import { EditProfileComponent } from './dialog/edit-profile/edit-profile.component';
registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    NavigatorBarComponent,
    HeaderComponent,
    QuestionsComponent,
    MainPanelComponent,
    InformationComponent,
    AuthComponent,
    RegistrationComponent,
    AnswerEnterComponent,
    TeamComponent,
    TeamCreateComponent,
    AddMemberComponent,
    LeaveTeamComponent,
    YesNoComponent,
    RememberPassComponent,
    EnterValueComponent,
    ProfileComponent,
    EditProfileComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AngularTiltModule,
    NgxMaskModule.forRoot(),
    MatRadioModule,
    MatDialogModule,
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    HttpClientModule,
    RouterModule.forRoot([
      {path: '', redirectTo: '/', pathMatch: 'full'},
      {path: 'login', component: AuthComponent},
      {path: 'registration', component: RegistrationComponent},
    ], {
      scrollPositionRestoration: 'enabled'
    }),
    MatOptionModule,
    // ToastrModule added
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: ParamInterceptorModule, multi: true},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    {
      provide: MatDialogRef,
      useValue: {}
    }, {provide: DatePipe}, { provide: LOCALE_ID, useValue: 'ru' }],
  entryComponents: [
    AnswerEnterComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
