import {Component, Input, OnInit} from '@angular/core';
import {DataHandlerService} from '../../service/data-handler.service';
import {User} from '../../model/user';
import {Team} from '../../model/team';
import {MatDialog} from '@angular/material/dialog';
import {AnswerEnterComponent} from '../../dialog/answer-enter/answer-enter.component';

@Component({
  selector: 'app-navigator-bar',
  templateUrl: './navigator-bar.component.html',
  styleUrls: ['./navigator-bar.component.css']
})
export class NavigatorBarComponent implements OnInit {

  @Input() user: User;
  page: string;

  constructor(private dataHandler: DataHandlerService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.dataHandler.pageSubject.subscribe(page => {this.page = page; } );
    this.page = 'Home';
  }

  switchPage(page: string): void{
    this.dataHandler.setPage(page);
  }

  // tslint:disable-next-line:typedef
  openDialog() {
    const dialogRef = this.dialog.open(AnswerEnterComponent, { disableClose: false });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
