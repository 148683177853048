import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {ServerService} from '../../service/server.service';
import {DataHandlerService} from '../../service/data-handler.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-leave-team',
  templateUrl: './leave-team.component.html',
  styleUrls: ['./leave-team.component.css']
})
export class LeaveTeamComponent implements OnInit {

  title: string;
  constructor(private srv: ServerService, private dataHendler: DataHandlerService, @Inject(MAT_DIALOG_DATA) public data: any,
              // tslint:disable-next-line:max-line-length
              private toast: ToastrService, private scroll: ViewportScroller, private cd: ChangeDetectorRef, public dialogRef: MatDialogRef<LeaveTeamComponent>) { }


  ngOnInit(): void {
    this.title = this.data.title;
  }

}
