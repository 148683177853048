

  <div class="card">

  <div class="card-body">
    <h4 class="card-title">Реєстрація команди!</h4>
    <form class="forms-sample" method="POST">


      <div class="form-group">
        <label for="titleID">Вкажи назву команди:</label>
        <div class="value">
          <div class="input-group">
            <input id="titleID"  [(ngModel)]="team.title" class="form-control" type="text" #titleE="ngModel" name="title"
                   minlength="6"  placeholder="Мінімум 6 символів" [class.form-control-danger]="!titleE.valid && !titleE.untouched">
            <label id="title-error" class="error mt-2 text-danger" for="titleID" *ngIf="!titleE.valid && !titleE.untouched">Введіть назву не коротше 6 символів</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="city" class="name">Вкажи місцевість:</label>
        <div class="value">
          <div class="input-group">
            <input  class="form-control" id="city" type="text"  [(ngModel)]="team.city" type="text" #cityE="ngModel" name="city">
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="paroch" class="name">Парафія команди, якщо маєте:</label>
        <div class="value">
          <div class="input-group">
            <input  class="form-control" id="paroch"  [(ngModel)]="team.paroch" type="text" #parochE="ngModel" name="paroch"
            >
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="about" class="name">Розкажіть трішки про себе:</label>
        <div class="value">
          <div class="input-group">
            <textarea rows="3" class="form-control" id="about"  [(ngModel)]="team.about" type="text" #aboutE="ngModel" name="about"
                   ></textarea>
           </div>
        </div>
      </div>



      <div class="align-content-center align-items-center">
        <p><button  type="submit" class="btn btn-gradient-primary " (click)="addTeam()"  >Створити команду</button>      <button class="btn ml-2 btn-light">Відмінити</button></p>

      </div>
    </form>
  </div>
  </div>

