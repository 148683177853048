import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {ServerService} from '../../service/server.service';
import {DataHandlerService} from '../../service/data-handler.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ViewportScroller} from '@angular/common';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-enter-value',
  templateUrl: './enter-value.component.html',
  styleUrls: ['./enter-value.component.css']
})
export class EnterValueComponent implements OnInit {

  constructor(private srv: ServerService, private dataHendler: DataHandlerService, @Inject(MAT_DIALOG_DATA) public data: any,
              // tslint:disable-next-line:max-line-length
              private toast: ToastrService, private scroll: ViewportScroller,
              private cd: ChangeDetectorRef, private dialogRef: MatDialogRef<EnterValueComponent>) { }
  value: string;
  valueName: string;
  title: string;
  dataKey: any;

  ngOnInit(): void {
    this.dataKey = this.data.dataKey;
    this.title = this.data.title;
    this.valueName = this.data.valueName;
    this.value = this.data.value;
  }

  send(result: boolean): void{
    this.dataKey.value = this.value;
    this.dataKey.answer = result;
    this.dialogRef.close(this.dataKey);
  }

}
