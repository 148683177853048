<div #myEl>
<h2 mat-dialog-title>Вкажіть на яке завдання хочете дати відповідь:</h2>
  <div class="container">
  <mat-dialog-content>

<form  mat-dialog-content class="forms-sample">

  <div class="d-flex form-group" data-select2-id="7">
    <mat-select  class=" select2-hidden-accessible" style="width:100%" id="mat-select-value-1" tabindex="-1" aria-hidden="false">
      <mat-option value="AL" data-select2-id="1" *ngFor="let question of questions">{{question ? question.title : 'Відсутні завдання'}}</mat-option>
    </mat-select>
  </div>

  <div id="inputAnswer" class="d-flex form-group">
    <label for="exampleInputUsername1"></label>
    <input type="text" class="form-control input-answer" id="exampleInputUsername1" placeholder="Відповідь">
  </div>

</form>
</mat-dialog-content>
  </div>
<mat-dialog-actions align="end">
  <button [mat-dialog-close]="true" cdkFocusInitial type="submit" class="btn btn-gradient-primary mr-2">Перевірити</button>
  <button mat-dialog-close class="btn btn-gradient-info btn-light">Закрити</button>
</mat-dialog-actions>
</div>
