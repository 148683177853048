import {Component, OnInit} from '@angular/core';
import {Team} from '../../model/team';
import {DataHandlerService} from '../../service/data-handler.service';
import {ServerService} from '../../service/server.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {

  teams: Team[];

  constructor(private dataHandler: DataHandlerService, private srv: ServerService) {
  }

  ngOnInit(): void {
    this.dataHandler.teamsSubject.subscribe(teamsS => {
      this.teams = teamsS;
    });
    this.srv.getTeams().subscribe(teams => {
      this.dataHandler.teams = teams;
      this.dataHandler.fillTeams();
    });
  }

  async getMembership1(id: number): Promise<number> {
    this.srv.getUsersByTeam(id).subscribe(users => {
      if (users !== null) {
        return users.length;
      } else {
        return 0;
      }
    });
    return 0;
  }

}
