import {Component, OnInit} from '@angular/core';
import {DataHandlerService} from '../../service/data-handler.service';
import {ServerService} from '../../service/server.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {AddMemberComponent} from '../../dialog/add-member/add-member.component';
import {RememberPassComponent} from '../../dialog/remember-pass/remember-pass.component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  login: string;
  pass: string;
  constructor(private dataHandler: DataHandlerService, private srv: ServerService, public dialog: MatDialog,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
  }

  logining(): void {
    this.srv.login(this.login, this.pass).subscribe(resp => this.afterLogin(resp));
  }

  afterLogin(resp: any): void {
    if (resp.status === 200) {
      localStorage.setItem('SESSIONID', resp.body.token);
      this.srv.getOneUser(resp.body.id).subscribe(user => {
        this.dataHandler.user = user;
        if (this.dataHandler.user.logo === null || this.dataHandler.user.logo === '') {
          this.dataHandler.user.logo = 'assets/img/no-ava.png';
        }
        this.dataHandler.fillUser();
      });
      this.dataHandler.enterAuth();
    } else {
      this.toastr.error('Не вірні дані для входу!');
    }
  }

  setPageReg(): void {
    this.dataHandler.setPage('registration');
  }

  logout(): void {
    this.dataHandler.exitAuth();
  }

  rememberPass(): void{
    const dialogRef = this.dialog.open(RememberPassComponent, { disableClose: true, data: {
      } });
  }
}
