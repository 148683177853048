<!-- partial:partials/_navbar.html -->
<nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
    <a class="navbar-brand brand-logo" href="#"><img src="assets/img/logoI.svg" alt="logo" /></a>
    <a class="navbar-brand brand-logo-mini" href="#"><img src="assets/img/logoI.svg" alt="logo" /></a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-stretch">
    <button (click)="clickedMenu()" class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
      <span class="mdi mdi-menu"></span>
    </button>
    <div class="search-field d-none d-md-block">
      <form class="d-flex align-items-center h-100" action="#">
        <!--
        <div class="input-group">
          <div class="input-group-prepend bg-transparent">
            <i class="input-group-text border-0 mdi mdi-magnify"></i>
          </div>
          <input type="text" class="form-control bg-transparent border-0" placeholder="Search projects">
        </div>
        -->
      </form>
    </div>
    <ul class="navbar-nav navbar-nav-right">
      <li class="nav-item nav-profile dropdown">
        <a #profilElementIcon (click)="clickedProfile()" class="nav-link dropdown-toggle" id="profileDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
          <div class="nav-profile-img">

            <img src="{{user ? user.logo : 'assets/img/no-ava.png'}}" alt="image">
            <span class="availability-status online"></span>
          </div>
          <div class="nav-profile-text">
            <p class="mb-1 text-black">{{user ? user.name : 'Не авторизовано!'}}</p>
          </div>
        </a>
        <div #profilElement class="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
          <a (click)="switchPage('Profile')" class="dropdown-item" href="#">
            <i class="mdi mdi-face-profile mr-2 text-success"></i> Особисті дані </a>
          <div class="dropdown-divider"></div>
          <a (click)="logout()" class="dropdown-item" href="#">
            <i  class="mdi mdi-logout mr-2 text-primary"></i> Вихід з профілю </a>
        </div>
      </li>
      <li class="nav-item d-none d-lg-block full-screen-link">
        <a (click)="openFullscreen()" class="nav-link">
          <i class="mdi mdi-fullscreen" id="fullscreen-button"></i>
        </a>
      </li>
      <li class="nav-item dropdown" >
        <a #emailEl (click)="clicked()" [class.count-indicator] = 'newNotifications && newNotifications.length != 0' class="nav-link dropdown-toggle" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
          <i class="mdi mdi-bell-outline"></i>
          <span class="count-symbol bg-pinterest"></span>
        </a>
        <div  #messageEl class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
          <h6 class="p-3 text-center mb-0">{{newNotifications && newNotifications.length != 0 ? "Сповіщення" : " Нові сповіщення відсутні!"}}</h6>
          <div *ngFor="let message of newNotifications, let i = index">
          <div class="dropdown-divider"></div>
          <a (click)="[openDialog(i)]" class="dropdown-item preview-item">
            <div class="preview-thumbnail">
              <img src="{{message.userSender && message.senderLogo !== '' ? message.senderLogo : 'assets/img/no-ava.png'}}" alt="image" class="profile-pic">
            </div>
            <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 class="preview-subject ellipsis mb-1 font-weight-normal">{{message.message}}</h6>
              <p class="text-gray mb-0">{{dateP.transform(message.date,'short')}}</p>
            </div>
          </a>
          </div>
          <a (click)="changeNotificationContainer()" class="dropdown-item preview-item">
            <h6 class="p-3 mb-0 text-center"> {{notifications ? notifications.length : 0}} сповіщень в системі! </h6></a>
        </div>
      </li>

    </ul>
    <button (click)="clickedMenuLeft()" class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
      <span class="mdi mdi-menu"></span>
    </button>
  </div>
</nav>
<!-- partial -->
