<div #myEl>
  <h2 mat-dialog-title>Вкажи e-mail адресу для відновлення</h2>
  <mat-dialog-content>
    <form mat-dialog-content class="forms-sample">


      <div id="inputEmail" class="form-group">
        <label for="exampleinputEmail"></label>
        <input [(ngModel)]="email" name="email" type="text" class="form-control" id="exampleinputEmail" placeholder="E-mail">
      </div>

    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="container">
    <button   (click)="send()" cdkFocusInitial type="submit" class="btn btn-gradient-primary mb-3 mr-2">Запросити зміну пароля</button>
    <button mat-dialog-close class="btn mr-2 mb-3 btn-gradient-info btn-light">Закрити</button></div>
  </mat-dialog-actions>

</div>
