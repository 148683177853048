<div class="main-panel">
  <div class="content-wrapper">

    <div [@inOutAnimation] *ngIf="page === 'Home'">
      <app-questions [user]="user"></app-questions>
    </div>
    <div [@inOutAnimation] *ngIf="page === 'Statistic'">
      <app-information></app-information>
    </div>
    <div [@inOutAnimation] *ngIf="page === 'Team'">
      <app-team [user]="user"></app-team>
    </div>
    <div [@inOutAnimation] *ngIf="page === 'TeamCreate'">
      <app-team-create [user]="user"></app-team-create>
    </div>
    <div [@inOutAnimation] *ngIf="page === 'Profile'">
      <app-profile [user]="user"></app-profile>
    </div>


  </div>

  <!-- content-wrapper ends -->
  <!-- partial:partials/_footer.html -->
  <footer class="footer">
    <div class="container-fluid clearfix">
      <span
        class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © bootstrapdash.com 2020</span>
      <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Free <a
        href="https://www.bootstrapdash.com/bootstrap-admin-template/" target="_blank">Bootstrap admin templates </a> from Bootstrapdash.com</span>
    </div>
  </footer>
  <!-- partial -->
</div>
<!-- main-panel ends -->
