import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DataHandlerService} from './service/data-handler.service';
import {ToastrService} from 'ngx-toastr';
import {animate, style, state, transition, trigger} from '@angular/animations';
import {User} from './model/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0, 'overflow-x': 'hidden'}),
            animate('1s ease-out',
              style({height: 1200, opacity: 1, 'overflow-x': 'hidden'}))
          ]
        ),
        transition(
          ':leave',
          [
            style({height: 1200, opacity: 1, 'overflow-x': 'hidden'}),
            animate('1s ease-in',
              style({height: 0, opacity: 0, 'overflow-x': 'hidden'}))
          ]
        )
      ]
    )
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'Hacaton';
  isAuth: boolean;
  page: string;
  user: User;

  constructor(private dataHandler: DataHandlerService, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.dataHandler.pageSubject.subscribe(page => {
      this.switchPage(page);
    });
    this.page = 'auth';
    this.isAuth = this.dataHandler.isLogining();
    this.dataHandler.authSubject.subscribe(isAuth => {
      this.showSuccess(isAuth);
      this.isAuth = isAuth;
    });
    this.dataHandler.userSubject.subscribe(user => this.user = user);
    this.dataHandler.initLogining();

  }

  switchPage(page: string): void {
    this.page = page;
  }

  showSuccess(isAuth: boolean): void {
    if (isAuth && !this.isAuth) {
      this.toastr.success('Вхід успішний!', 'Вітаю!');
    } else if (!isAuth && this.isAuth) {
      this.toastr.info('Допобачення!', 'Вихід виконано!');
    }
    if (isAuth && this.isAuth) {
      this.dataHandler.initUser();
    }
  }


}
