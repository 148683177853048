<div class="page-content page-container" id="page-content">
  <div class="card user-card-full">
    <div class="row m-l-0 m-r-0">
      <div class="col-sm-4 bg-c-lite-green user-profile">
        <div class="card-block text-center text-white">
          <div class="m-b-25">  <div class="nav-profile-image"><img src="{{user ? user.logo : 'assets/img/no-ava.png'}}" class="img-radius user-profile-image" alt="User-Profile-Image"></div> </div>
          <h6 class="f-w-600">{{user.name}}</h6>
          <p>###{{user.id}}###</p> <a (click)="editProfile()"><i class=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i></a>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="card-block">
          <h6 class="m-b-20 p-b-5 b-b-default f-w-600">Деталі</h6>
          <div class="row">
            <div class="col-sm-6">
              <p class="m-b-10 f-w-600">E-mail</p>
              <h6 class="text-muted f-w-400">{{user.email}}</h6>
            </div>
            <div class="col-sm-6">
              <p class="m-b-10 f-w-600">Телефон</p>
              <h6 class="text-muted f-w-400">{{user.telephone}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <p class="m-b-10 f-w-600">Місцевість</p>
              <h6 class="text-muted f-w-400">{{user.city}}</h6>
            </div>
            <div class="col-sm-6">
              <p class="m-b-10 f-w-600">Парафія</p>
              <h6 class="text-muted f-w-400">{{user.paroch}}</h6>
            </div>
          </div>
          <h6 class="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">Команда</h6>
          <div class="row">
            <div class="col-sm-6">
              <p class="m-b-10 f-w-600">{{user && user.team ? user.team.title : 'Без команди'}}</p>
              <h6 class="text-muted f-w-400">Найкращий учасник</h6>
            </div>
          </div>
          <ul class="social-link list-unstyled m-t-40 m-b-10">
            <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="facebook" data-abc="true"><i class="mdi mdi-facebook feather icon-facebook facebook" aria-hidden="true"></i></a></li>
            <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="twitter" data-abc="true"><i class="mdi mdi-twitter feather icon-twitter twitter" aria-hidden="true"></i></a></li>
            <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="instagram" data-abc="true"><i class="mdi mdi-instagram feather icon-instagram instagram" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>



