import {Team} from './team';

export class User {
  id: number;
  name: string;
  password: string;
  email: string;
  city: string;
  telephone: string;
  team: Team;
  logo: string;
  paroch: string;

  constructor(id: number, name: string, password: string, email: string, city?: string, telephone?: string, team?: Team, paroch?: string) {
    this.id = id;
    this.name = name;
    this.password = password;
    this.email = email;
    this.city = city;
    this.telephone = telephone;
    this.team = team;
    this.paroch = paroch;
  }
}
