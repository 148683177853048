import {AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Question} from '../../model/question';
import {ServerService} from '../../service/server.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-answer-enter',
  templateUrl: './answer-enter.component.html',
  styleUrls: ['./answer-enter.component.css', './answe-enter.mystyle.scss' ]
})
export class AnswerEnterComponent implements OnInit, AfterViewInit {

  @ViewChild('myEl') myEl: ElementRef;
  questions: Question[];
  initiate: boolean;

  constructor(private srv: ServerService, private dialogRef: MatDialogRef<AnswerEnterComponent>) {
  }


  ngOnInit(): void {
    this.srv.getQuestions().subscribe(resp => this.questions = resp);
    this.initiate = false;
  }

  ngAfterViewInit(): void {
    this.initiate = true;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    // console.log(event);
    if (this.myEl.nativeElement.contains(event.target)) {
      // this.dialogRef.close();
    }

  }
}


