
<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <div class="login100-pic lpp" data-tilt aTilt>
        <img src="../../../assets/img/JesuS.jpg" alt="IMG">
      </div>

      <form class="login100-form validate-form">
					<span class="login100-form-title">
						Авторизація
					</span>

        <div class="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
          <input [(ngModel)]="login" class="input100" type="text" name="email" placeholder="E-mail">
          <span class="focus-input100"></span>
          <span class="symbol-input100">
							<i class="fa fa-envelope" aria-hidden="true"></i>
						</span>
        </div>

        <div class="wrap-input100 validate-input" data-validate = "Password is required">
          <input [(ngModel)]="pass" class="input100" type="password" name="pass" placeholder="Пароль">
          <span class="focus-input100"></span>
          <span class="symbol-input100">
							<i class="fa fa-lock" aria-hidden="true"></i>
						</span>
        </div>

        <div class="container-login100-form-btn">
          <button class="login100-form-btn" (click)="logining()">
            Увійти
          </button>
        </div>

        <div class="text-center p-t-12">
						<span class="txt1">
							Забув
						</span>
          <a (click)="rememberPass()" class="txt2" href="#">
            Нікнейм / Пароль?
          </a>
        </div>

        <div class="text-center p-t-136">
          <a class="txt2" href="#" (click)="setPageReg()">
            Зареєструватись
            <i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
