<div class="card">
  <div class="card-body">
    <div class="d-flex justify-content-end mb-4">
      <h5 class="mr-2 font-weight-semibold border-right pr-2 mr-2">Команд</h5>
      <h5 class="font-weight-semibold">{{teams ? teams.length : 0}}</h5>
    </div>
    <div class="row mx-sm-0">

      <div class="col-md-12 mb-5 pt-2 border px-0" *ngFor="let team of teams">
        <div class="card rounded shadow-none">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 col-lg-5 d-lg-flex">
                <div class="user-avatar mb-auto">
                  <img src="{{team.image && team.image!=='' ? team.image : 'assets/img/no-team.png'}}"
                       alt="profile image" class="profile-img img-lg rounded-circle">
                  <span class="edit-avatar-icon"><i class="mdi mdi-camera"></i></span>
                </div>
                <div class="wrapper pl-lg-4">
                  <div class="wrapper d-flex align-items-center">
                    <h4 class="mb-0 font-weight-medium">{{team.title}}</h4>
                  </div>
                  <div class="wrapper d-flex align-items-center font-weight-medium text-muted">
                    <i class="mdi mdi-map-marker-outline mr-2"></i>
                    <p class="mb-0 text-muted">{{team.city}}</p>
                  </div>
                  <div class="wrapper d-flex align-items-start pt-3">
                    <div class="badge badge-secondary text-dark mr-2">
                      <i class="mdi mdi-check-circle-outline icon-sm"></i>
                    </div>
                    <div class="badge badge-secondary text-dark mr-2">
                      <i class="mdi mdi-email-outline icon-sm"></i>
                    </div>
                    <div class="badge badge-secondary text-dark mr-2">
                      <i class="mdi mdi-format-list-bulleted icon-sm"></i>
                    </div>
                    <div class="wrapper pl-2 d-none d-sm-block">
                      <h6 class="mt-n1 mb-0 font-weight-medium"></h6>
                      <p class="text-muted">Учасників</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-5">
                <div class="d-flex align-items-center w-100">
                  <p class="mb-0 mr-3 font-weight-semibold">Progress</p>
                  <div class="progress progress-md w-100">
                    <div class="progress-bar bg-success" role="progressbar" style="width: 45%" aria-valuenow="45"
                         aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <p class="mb-0 ml-3 font-weight-semibold">45%</p>
                </div>
                <p class="text-muted mt-4">{{team.about}}</p>
              </div>
              <div class="col-sm-6 col-lg-2">
                <div class="wrapper d-flex">
                  <button type="button" class="btn btn-sm btn-gradient-primary mr-2">ASK</button>
                  <button type="button" class="btn btn-sm btn-gradient-info">HIRE</button>
                </div>
              </div>
            </div>
          </div>
          <div class="wrapper border-top">
            <div class="card-body">
              <div class="row">
                <div class="col d-flex">
                  <div
                    class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                    <i class="mdi mdi-traffic-light icon-sm my-0 "></i>
                  </div>
                  <div class="wrapper pl-3">
                    <p class="mb-0 font-weight-medium text-muted">TOTAL TRAFFIC</p>
                    <h4 class="font-weight-semibold mb-0">443,235</h4>
                  </div>
                </div>
                <div class="col d-flex">
                  <div
                    class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                    <i class="mdi mdi-account-plus icon-sm my-0 "></i>
                  </div>
                  <div class="wrapper pl-3">
                    <p class="mb-0 font-weight-medium text-muted">NEW USERS</p>
                    <h4 class="font-weight-semibold mb-0">3,433</h4>
                  </div>
                </div>
                <div class="col d-flex">
                  <div
                    class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                    <i class="mdi mdi-server-security icon-sm my-0 "></i>
                  </div>
                  <div class="wrapper pl-3">
                    <p class="mb-0 font-weight-medium text-muted">SALES</p>
                    <h4 class="font-weight-semibold mb-0">345</h4>
                  </div>
                </div>
                <div class="col d-flex">
                  <div
                    class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                    <i class="mdi mdi-chart-arc icon-sm my-0 "></i>
                  </div>
                  <div class="wrapper pl-3">
                    <p class="mb-0 font-weight-medium text-muted">PERFORMANCE</p>
                    <h4 class="font-weight-semibold mb-0 text-primary">34.43%</h4>
                  </div>
                </div>
                <div class="col d-flex align-items-center">
                  <div class="image-grouped ml-auto">
                    <img src="../../../assets/img/faces/face11.jpg" data-toggle="tooltip" data-placement="top" title=""
                         alt="profile image" data-original-title="Mary Sharp">
                    <img src="../../../assets/img/faces/face12.jpg" data-toggle="tooltip" data-placement="top" title=""
                         alt="profile image" data-original-title="Cory Medina">
                    <img src="../../../assets/img/faces/face23.jpg" data-toggle="tooltip" data-placement="top" title=""
                         alt="profile image" data-original-title="Clyde Hammond">
                    <div class="text-avatar" data-toggle="tooltip" data-placement="top" title=""
                         data-original-title="4 More Peoples"><span class="d-block pt-2">+4</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

