import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ServerService} from '../../service/server.service';
import {User} from '../../model/user';
import {DataHandlerService} from '../../service/data-handler.service';
import {catchError} from 'rxjs/operators';
import {animate, style, transition, trigger} from '@angular/animations';
import {Toast, ToastrService} from 'ngx-toastr';
import {NgModel} from '@angular/forms';
import {  ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0}),
            animate('1s 1100ms ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('1s ease-in',
              style({opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class RegistrationComponent implements OnInit {
  @ViewChild('emailE') emailE: NgModel;
  @ViewChild('first_name') firstName: NgModel;
  @ViewChild('passwordE') passwordE: NgModel;
  @ViewChild('tel') tel: NgModel;

  constructor(private srv: ServerService, private dataHendler: DataHandlerService,
              private toast: ToastrService, private scroll: ViewportScroller) {
  }

  isAgree: boolean;
  name: string;
  surname: string;
  city: string;
  user: User;
  paroch: string;
  telephone: string;
  email: string;
  password: string;

  ngOnInit(): void {
    this.isAgree = false;
    this.user = new User(0, '', '', '');
  }

  addUsers(): void {
    this.user.name = this.name + ' ' + this.surname;
    this.user.paroch = this.paroch;
    this.user.city = this.city;
    this.user.email = this.email;
    this.user.telephone = this.telephone;
    this.user.password = this.password;
    if (this.emailE.control.status === 'VALID' && this.passwordE.control.status === 'VALID' &&
      this.firstName.control.status === 'VALID') {
      this.srv.addUser(this.user).subscribe(resp => this.afterRegistration(resp), catchError(this.srv.handleError('addUser', [])));
    } else {
      this.toast.error('Введіть вірні дані');
      this.scroll.scrollToPosition([0, 0]);
    }
  }

  afterRegistration(resp: any): void {
    if (resp.status === 200) {
      this.toast.success('Використайте дані для входу', 'Реєстрація успішна!');
      this.dataHendler.setPage('Enter');
    } else if (resp.error.error.includes('data_user_email_key')) {
      this.toast.error('Використайте форму відновлення доступу', 'Даний емейл уже використовується');
    } else {
      this.toast.error(resp.error);
    }
  }
}
