import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {ServerService} from '../../service/server.service';
import {DataHandlerService} from '../../service/data-handler.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-remember-pass',
  templateUrl: './remember-pass.component.html',
  styleUrls: ['./remember-pass.component.css']
})
export class RememberPassComponent implements OnInit {

  constructor(private srv: ServerService, private dataHendler: DataHandlerService, @Inject(MAT_DIALOG_DATA) public data: any,
              // tslint:disable-next-line:max-line-length
              private toast: ToastrService, private scroll: ViewportScroller, private cd: ChangeDetectorRef, private dialogRef: MatDialogRef<RememberPassComponent>) { }
  email: string;

  ngOnInit(): void {
  }

  send(): void{
    if (this.email){
      this.srv.getUserByEmailWAUTH(this.email).subscribe(resp => this.afterUserGet(resp), catchError(this.srv.handleError('addUser', [])));
    }
    else {
      this.toast.info('Введіть e-mail!');
    }
  }


  afterUserGet(resp: any): void {
    if (resp) {
      this.toast.success(resp.name, 'Знайдено!');
      const pass = this.dataHendler.randomString();
      this.srv.updateUserPassWAUTH(resp.id, pass).subscribe(respion => {
        this.srv.updateUserPassSendMail(this.email, pass).subscribe(respe => {
          this.toast.success('Вислано на вказаний e-mail!', 'Встановлено новий пароль!');
          this.dialogRef.close();
        });
      });
    } else {
          this.toast.error('Не знайдено зареєстрованого користувача');
    }
  }
}
