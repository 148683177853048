import {Component, Input, OnInit} from '@angular/core';
import {Team} from '../../model/team';
import {User} from '../../model/user';
import {from} from 'rxjs';
import {ServerService} from '../../service/server.service';
import {DataHandlerService} from '../../service/data-handler.service';
import {AnswerEnterComponent} from '../../dialog/answer-enter/answer-enter.component';
import {AddMemberComponent} from '../../dialog/add-member/add-member.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LeaveTeamComponent} from '../../dialog/leave-team/leave-team.component';
import {ToastrService} from 'ngx-toastr';
import {YesNoComponent} from '../../dialog/yes-no/yes-no.component';
import {EnterValueComponent} from '../../dialog/enter-value/enter-value.component';
import {catchError} from 'rxjs/operators';
import {NotificationIn} from '../../model/notification-in';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  @Input() user: User;
  sel1: boolean;
  sel2: boolean;
  sel3: boolean;
  sel4: boolean;
  sel5: boolean;
  selected: number;
  users: User[];
  textUsers: User[];
  isItCaptain: boolean;

  constructor(private srv: ServerService, private dhs: DataHandlerService, public dialog: MatDialog, private toast: ToastrService) {
  }

  ngOnInit(): void {
    this.selected = 0;
    this.dhs.teamSubject.subscribe(team => {
      this.srv.getUsersByTeam(team.id).subscribe(users => this.users = users);
      this.srv.getTextUserByTeam(team.id).subscribe(users => this.textUsers = users);
      if (team.captain.id === this.user.id){
        this.isItCaptain = true;
      }
      else {
        this.isItCaptain = false;
      }
    });
    if (this.users === undefined && this.user.team !== null) {
        this.dhs.fillTeam();
    }
  }

  mouseOverUse(sel: number): void {
    let i;
    for (i = 1; i <= 5; i++) {
      this['sel' + i.toString()] = i <= sel;
    }
  }

  intialRating(): void {
    let i;
    for (i = 1; i <= 5; i++) {
      this['sel' + i.toString()] = i <= this.selected;
    }
  }

  switchPage(page: string): void{
    this.dhs.setPage(page);
  }

  // tslint:disable-next-line:typedef
  openDialog() {
    const dialogRef = this.dialog.open(AddMemberComponent, { disableClose: true, data: {
        dataKey: this.user
      } });

    dialogRef.afterClosed().subscribe(result => {
      this.srv.getUsersByTeam(this.user.team.id).subscribe(users => this.users = users);
      this.srv.getTextUserByTeam(this.user.team.id).subscribe(users => this.textUsers = users);
    });
  }
  // tslint:disable-next-line:typedef
  leaveTeam() {
    const dialogRef = this.dialog.open(LeaveTeamComponent, { disableClose: true, data: {
        title: 'Ви впевнені, що хочете покинути команду? Якщо ви останній зареєстрований учасник, команда видалиться!'
      } });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.srv.getUsersByTeam(this.user.team.id).subscribe(users => {
          if (users.length === 1) {
            // @ts-ignore
            this.srv.deleteTextUserTeam(users[0].team).subscribe(respDel => {
            });
            // @ts-ignore
            this.srv.deleteTeamById(users[0].team).subscribe(respDel => {
              this.toast.success('Команда видалена!');
            });
          }
          this.user.team = null;
          this.srv.updateUser(this.user).subscribe(resp => {
            if (resp.status === 200) {
              this.dhs.teamSubject.next(null);
              this.toast.success('Вихід з команди виконано!');
            } else {
              this.toast.error(resp.error.error);
            }
          });
        });
      }
    });
  }
  kickFromTeam(i: number, users: boolean): void{
    let dialogRef: MatDialogRef<YesNoComponent>;
    if (users){
      if (this.users[i].id === this.user.id){
        this.toast.error('Використайте кнопку виходу вверху!', 'Не можна видаляти себе з команди!');
        return;
      }
      dialogRef = this.dialog.open(YesNoComponent, { disableClose: true, data: {
          title: 'Ви впевнені, що хочете видалити ' + this.users[i].name + ' з команди?',
          dataKey: {index: i, users: true, notif: false, id: this.users[i].id, answer: false},
          notif: false
        } });
    }
    else {
        dialogRef = this.dialog.open(YesNoComponent, { disableClose: true, data: {
          title: 'Ви впевнені, що хочете видалити ' + this.textUsers[i].name + ' з команди?',
          dataKey: {index: i, users: false, notif: false, id: this.textUsers[i].id, answer: false},
          notif: false
        } });
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result.answer){
        if (result.users) {
          const userUp = this.users[result.index];
          userUp.team = null;
          this.srv.updateUser(userUp).subscribe(resp => {
            this.srv.getUsersByTeam(this.user.team.id).subscribe(userio => this.users = userio);
          });
        }
        else {
          this.srv.deleteTextUser(this.textUsers[result.index].id).subscribe(resp => {
            this.srv.getTextUserByTeam(this.user.team.id).subscribe(userio => this.textUsers = userio);
          });
        }
      }
    });
  }

  changeCaptain(): void{
    const dialogRef = this.dialog.open(EnterValueComponent, { disableClose: true, data: {
        dataKey: this.user, title: 'Введіть E-mail зареєстрованого учасника команди для передачі:',
        value: '', valueName: 'E-mail', answer: false
      } });

    dialogRef.afterClosed().subscribe(result => {
      if (result.answer){
        this.srv.getUserByEmail(result.value).subscribe(resp => this.afterUserGet(resp), catchError(this.srv.handleError('addUser', [])));
      }
    });
  }

  afterUserGet(resp: any): void {
    if (resp) {
      if (resp.team === this.user.team.id) {
        this.user.team.captain = resp;
        this.srv.updateTeam(this.user.team).subscribe( respio => {
          this.dhs.teamSubject.next(this.user.team);
          this.toast.success('Капітана оновлено!');
        });
      }
      else {
        this.toast.error('Даний учасник не належить цій команді!');
      }
    } else {
      this.toast.error('Такий учасник не зареєстрований!');
    }
    }
}
