import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Team} from '../../model/team';
import {User} from '../../model/user';
import {from} from 'rxjs';
import {ServerService} from '../../service/server.service';
import {DataHandlerService} from '../../service/data-handler.service';
import {NgModel} from '@angular/forms';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-team-create',
  templateUrl: './team-create.component.html',
  styleUrls: ['./team-create.component.css']
})
export class TeamCreateComponent implements OnInit {

  @Input() user: User;
  @ViewChild('titleE') titleE: NgModel;
  @ViewChild('aboutE') aboutE: NgModel;
  @ViewChild('cityE') cityE: NgModel;
  @ViewChild('parochE') parochE: NgModel;

  team: Team;
  constructor(private srv: ServerService, private dhs: DataHandlerService, private toast: ToastrService) { }

  ngOnInit(): void {
    this.team = this.user.team;
    if (this.team === null){
      this.team = new Team(0, '', this.user, 'assets/img/no-team.png');
    }
    console.log('Test');
  }
  addTeam(): void{
    if (this.titleE.control.status === 'VALID') {
      this.team.captain = this.user;
      this.srv.addTeam(this.team).subscribe(resp => this.afterAddTeam(resp), catchError(this.srv.handleError('addTeam', [])));
    } else {
      this.toast.error('Введіть вірні дані');
    }
  }

  afterAddTeam(resp: any): void {
    if (resp.status === 200) {
      this.team.id = resp.body.id;
      this.user.team = this.team;
      this.user.team.captain = null;
      this.srv.updateUser(this.user).subscribe(resps => this.afterUpdateUser(resps), catchError(this.srv.handleError('addTeam', [])));
    } else if (resp.error.error.includes('data_team_title_key')) {
      this.toast.error('Використайте іншу назву', 'Дана назва уже використовується');
    } else {
      this.toast.error(resp.error.error);
    }
  }

  afterUpdateUser(resp: any): void {
    if (resp.status === 200) {
      this.toast.success('Вітаємо, Капітан!', 'Команда зареєстрована!');
      this.user.team.captain = this.user;
      this.dhs.setPage('Home');
    } else {
      this.toast.error(resp.error.error);
    }
  }

}
