import {Injectable, OnInit} from '@angular/core';
import {User} from '../model/user';
import {TestData} from '../data/testData';
import {Subject} from 'rxjs';
import {Team} from '../model/team';
import {ServerService} from './server.service';
import {AuthIntercepService} from './auth-intercep.service';

@Injectable({
  providedIn: 'root'
})
export class DataHandlerService {

  pageSubject = new Subject<string>();
  page: string;
  authSubject = new Subject<boolean>();
  isAuth: boolean;
  user: User;
  userSubject = new Subject<User>();
  teamsSubject = new Subject<Team[]>();
  teamSubject = new Subject<Team>();
  teams: Team[];

  constructor(private srv: AuthIntercepService) {
  }


  fillPage(): void {
    this.pageSubject.next(this.page);
  }

  getUser(): User {
    return this.user;
  }

  fillUser(): void {
    this.userSubject.next(this.user);
  }

  fillTeam(): void {
    this.teamSubject.next(this.user.team);
  }
  fillAuth(): void {
    this.authSubject.next(this.isAuth);
  }

  enterAuth(): void {
    this.isAuth = true;
    this.authSubject.next(this.isAuth);
    this.fillAuth();
  }

  exitAuth(): void {
    this.isAuth = false;
    localStorage.removeItem('SESSIONID');
    this.authSubject.next(this.isAuth);
  }

  getTeamByCaptain(captain: User): Team[] {
    return TestData.teams.filter(team => team.captain === captain);
  }

  setPage(page: string): void {
    this.page = page;
    this.pageSubject.next(this.page);
  }

  fillTeams(): void {
    this.teamsSubject.next(this.teams);
  }

  isLogining(): boolean {
    return (localStorage.getItem('SESSIONID') !== null);
  }

  initLogining(): void {
    this.isAuth = this.isLogining();
    this.fillAuth();
  }
  randomString(): string {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    const stringLength = 10;
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  initUser(): void {
    if (this.isAuth) {
      const resp = this.srv.getId().subscribe(respo => {
        if (respo.status === 403 || respo.status === 401) {
          this.exitAuth();
        } else {
          this.srv.getOneUser(respo.id).subscribe(user => {
            this.user = user;
            if (this.user.logo === null || this.user.logo === '') {
              this.user.logo = 'assets/img/no-ava.png';
            }
            const iter = user.team;
            this.user.team = null;
            if (iter !== null) {
              this.srv.getTeamById(iter).subscribe(team => {
                if (team !== null) {
                  this.user.team = team;
                  this.fillUser();
                  if (this.user.team.image === null || this.user.team.image === '') {
                    this.user.team.image = 'assets/img/no-team.png';
                  }
                  this.fillTeam();
                  this.srv.getOneUser(team.captain).subscribe(captain => {
                    this.user.team.captain = captain;
                    this.fillUser();
                  });
                }
              });
            }
            this.fillUser();
          });
        }
      });
    }
  }
}
