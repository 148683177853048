import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {ServerService} from '../../service/server.service';
import {DataHandlerService} from '../../service/data-handler.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.css']
})
export class YesNoComponent implements OnInit {

  title: string;
  dataKey: any;
  notif: boolean;
  constructor(private srv: ServerService, private dataHendler: DataHandlerService, @Inject(MAT_DIALOG_DATA) public data: any,
              // tslint:disable-next-line:max-line-length
              private toast: ToastrService, private scroll: ViewportScroller, private cd: ChangeDetectorRef, private dialogRef: MatDialogRef<YesNoComponent>) { }


  ngOnInit(): void {
    this.dataKey = this.data;
    this.title = this.data.title;
    if (this.data.notif) {
      this.notif = true;
    }
    else {
      this.notif = false;
    }
  }

  clickAnswer(result: boolean): void{
    this.dataKey.answer = result;
    this.dialogRef.close(this.dataKey);
  }
}
