import {Injectable} from '@angular/core';
import {of, Subject} from 'rxjs';
import {Team} from '../model/team';
import {User} from '../model/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry, tap, map} from 'rxjs/operators';
import {options} from '../app.module';
import {DataHandlerService} from './data-handler.service';
import {Question} from '../model/question';
import {NotificationIn, NotificationInNew} from '../model/notification-in';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  // private srvUrl = 'http://192.168.137.1:3000/';
  private srvUrl = 'https://s1.youfra.com.ua/';
  private log(message: string): void {
    console.log(message);
  }

  handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      if (error.status === 401) {
        this.dataHandler.exitAuth();
        this.dataHandler.fillAuth();
      }
      return of(error as T);
    };
  }

  constructor(private  http: HttpClient, private dataHandler: DataHandlerService) {
  }

  getUsers(): Observable<User[]> {
    return this.http.get(this.srvUrl + 'api/user').pipe(
      catchError(this.handleError('getUsers', []))
    );
  }

  getTeams(): Observable<Team[]> {
    return this.http.get(this.srvUrl + 'api/teams').pipe(
      catchError(this.handleError('Get teams', []))
    );
  }

  getOneUser(id: string): Observable<User> {
    return this.http.get(this.srvUrl + 'api/user/' + id).pipe(
      catchError(this.handleError('getOneUsers', []))
    );
  }

  addUser(user: User): Observable<User> {
    return this.http.post(this.srvUrl + 'api/user', user, {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('addUser', []))
    );
  }

  updateUser(user: User): Observable<any> {
    return this.http.put(this.srvUrl + 'api/user', user, {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('updateUser', []))
    );
  }

  updateUserPass(userId: number, pass: string): Observable<any> {
    return this.http.put(this.srvUrl + 'api/user-wa', {id: userId, password: pass}, {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('updateUser', []))
    );
  }

  updateUserPassWAUTH(userId: number, pass: string): Observable<any> {
    return this.http.put(this.srvUrl + 'api/user-wa', {id: userId, password: pass}, {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('updateUser', []))
    );
  }

  updateUserPassSendMail(emailP: string, pass: string): Observable<any> {
    return this.http.post(this.srvUrl + 'api/user-wa-send', {email: emailP, password: pass}, {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('updateUser', []))
    );
  }

  getUsersByTeam(id: number): Observable<User[]> {
    return this.http.get(this.srvUrl + 'api/user/team/' + id).pipe(
      catchError(this.handleError('getUsersByTeam', []))
    );
  }

  getUserByEmail(email: string): Observable<User> {
    return this.http.get(this.srvUrl + 'api/user-by-email/' + email).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('getUserByEmail', []))
    );
  }

  getUserByEmailWAUTH(email: string): Observable<User> {
    return this.http.get(this.srvUrl + 'api/user-by-email-wa/' + email).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('getUserByEmail', []))
    );
  }
  login(login: string, pass: string): Observable<any> {
    this.log(this.srvUrl + 'api/user/login');
    return this.http.post(this.srvUrl + 'api/user/login', {email: login, password: pass}, {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('loginUser', []))
    );
  }

  getId(): Observable<any> {
    return this.http.get(this.srvUrl + 'api/id').pipe(
      catchError(this.handleError('getId', []))
    );
  }

  getQuestions(): Observable<Question[]> {
    return this.http.get(this.srvUrl + 'api/question').pipe(
      catchError(this.handleError('get questions', []))
    );
  }

  getTeamById(id: number): Observable<Team> {
    return this.http.get(this.srvUrl + 'api/teams/' + id.toString()).pipe(
      catchError(this.handleError('Get team by id', []))
    );
  }

  deleteTeamById(id: number): Observable<Team> {
    return this.http.delete(this.srvUrl + 'api/teams/' + id.toString()).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('Delete team by id', []))
    );
  }

  getTeamByCaptain(id: number): Observable<Team> {
    return this.http.get(this.srvUrl + 'api/teams/captain/' + id.toString()).pipe(
      catchError(this.handleError('Get team by user', []))
    );
  }

  addTeam(team: Team): Observable<User> {
    return this.http.post(this.srvUrl + 'api/teams', team, {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('addTeam', []))
    );
  }

  addNotification(notif: NotificationIn): Observable<NotificationIn> {
    return this.http.post(this.srvUrl + 'api/notifications', notif, {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('addNotification', []))
    );
  }

  getNotificationByRecipient(id: number): Observable<NotificationIn[]> {
    return this.http.get(this.srvUrl + 'api/notifications/recipient/' + id.toString()).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('Get all notif by recipient', []))
    );
  }

  getNewNotificationByRecipient(id: number): Observable<NotificationInNew[]> {
    return this.http.get(this.srvUrl + 'api/notifications/recipient/new/' + id.toString()).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('Get new notif by recipient', []))
    );
  }

  getAllNotificationByRecipient(id: number): Observable<NotificationInNew[]> {
    return this.http.get(this.srvUrl + 'api/notifications/recipient/all/' + id.toString()).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('Get all notif by recipient', []))
    );
  }

  updateReadNotification(id: number): Observable<any> {
    return this.http.put(this.srvUrl + 'api/notifications/read/' + id.toString(), id.toString(),
      {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('updateUser', []))
    );
  }

  addTextUserToTeam(name: string, email: string, team: Team): Observable<any> {
    return this.http.post(this.srvUrl + 'api/text-user',
      { name: name.toString(), email: email.toString(), team}, {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('addUser', []))
    );
  }

  deleteTextUser(id: number): Observable<any> {
    return this.http.delete(this.srvUrl + 'api/text-user/' + id.toString()).pipe((response => {
        return response;
      }),
      catchError(this.handleError('Delete TextUser', []))
    );
  }

  deleteTextUserTeam(id: number): Observable<any> {
    return this.http.delete(this.srvUrl + 'api/text-user/team/' + id.toString()).pipe((response => {
        return response;
      }),
      catchError(this.handleError('Delete Team TU', []))
    );
  }

  getTextUserByTeam(id: number): Observable<User[]> {
    return this.http.get(this.srvUrl + 'api/text-user/team/' + id.toString()).pipe(
      catchError(this.handleError('Get text users by team', []))
    );
  }

  deleteNotification(id: number): Observable<any> {
    return this.http.delete(this.srvUrl + 'api/notifications/' + id.toString()).pipe((response => {
        return response;
      }),
      catchError(this.handleError('Delete Notification', []))
    );
  }

  updateTeam(team: Team): Observable<any> {
    return this.http.put(this.srvUrl + 'api/teams', team, {observe: 'response'}).pipe(map(response => {
        return response;
      }),
      catchError(this.handleError('updateUser', []))
    );
  }
  postFile(fileToUpload: File): Observable<boolean> {
    const endpoint = 'api/files';
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http
      .post(endpoint, formData, ).pipe(
      map(response => {
        return response; }),
        catchError(this.handleError('updateUser', []))
      );
  }
}
