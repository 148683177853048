import {Component, Input, OnInit} from '@angular/core';
import {ServerService} from '../../service/server.service';
import {DataHandlerService} from '../../service/data-handler.service';
import {User} from '../../model/user';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {
  constructor(private srv: ServerService, private dhs: DataHandlerService) { }
  @Input() user: User;
  users: number;
  usersInTeam: number;
  teams: number;
  attempts: number;
  ngOnInit(): void {
    this.srv.getUsers().subscribe(users => this.users = users.length);
    this.srv.getTeams().subscribe(teams => this.teams = teams.length);
    this.dhs.teamSubject.subscribe(team => {
      this.srv.getUsersByTeam(team.id).subscribe(usersInTeam => this.usersInTeam = usersInTeam.length - 1);
    });
    this.attempts = 0;
  }

}
