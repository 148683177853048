import {ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {User} from '../../model/user';
import {ServerService} from '../../service/server.service';
import {DataHandlerService} from '../../service/data-handler.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ViewportScroller} from '@angular/common';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  title: string;
  dataKey: any;
  user: User;
  fileToUpload: File = null;
  constructor(private srv: ServerService, private dataHendler: DataHandlerService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toast: ToastrService, private scroll: ViewportScroller,
              private cd: ChangeDetectorRef, public dialogRef: MatDialogRef<EditProfileComponent>) { }

  ngOnInit(): void {
    this.user = this.data.dataKey;
    this.title = this.data.title;
  }
  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }
 saveProfile(): void{
    this.dialogRef.close(this.user);
 }


}
