
<div class="page-wrapper bg-gra-03 p-t-45 p-b-50">
  <div class="wrapper wrapper--w790">
    <div class="card card-5">

      <div class="card-heading">
        <div class="row">
        <h3 class="page-title  homecl col-1">
          <a href="index.html"> <span class="page-title-icon bg-gradient-primary text-white mr-2">
                     <i class="mdi mdi-home"></i>
                </span></a>
        </h3>
        <h2 class="title titlecl align-middle row-cols-xl-5">Реєстраційна Форма</h2></div>
      </div>
      <div class="card-body">
        <form method="POST">
          <div class="form-row m-b-55">
            <div class="name">Представся:</div>
            <div class="value">
              <div class="row row-space">
                <div class="col-2">
                  <div class="input-group-desc">
                    <input id="first_name" [(ngModel)]="name" class="input--style-5" minlength="2" type="text" name="first_name" #first_name="ngModel"
                           [class.form-control-danger]="!first_name.valid && !first_name.untouched">
                    <label class="label--desc">Ім'я</label>
                    <label id="cname-error" class="error mt-2 text-danger" for="first_name" *ngIf="!first_name.valid && !first_name.untouched"></label>
                  </div>
                </div>
                <div class="col-2">
                  <div class="input-group-desc">
                    <input id="last_name" [(ngModel)]="surname" class="input--style-5" type="text" name="last_name" #last_name="ngModel"
                           [class.form-control-danger]="!last_name.valid && !last_name.untouched">
                    <label class="label--desc">Прізвище</label>
                    <label id="lname-error" class="error mt-2 text-danger" for="last_name" *ngIf="!last_name.valid && !last_name.untouched"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="name">Email</div>
            <div class="value">
              <div class="input-group">
                <input id="email"  [(ngModel)]="email" class="input--style-5" type="email" #emailE="ngModel" name="email"
                       minlength="6"  [class.form-control-danger]="!emailE.valid && !emailE.untouched">
                <label id="email-error" class="error mt-2 text-danger" for="email" *ngIf="!emailE.valid && !emailE.untouched">Введіть вірний е-мейл</label>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="name">Пароль</div>
            <div class="value">
              <div class="input-group">
                <input id="pass" [(ngModel)]="password" class="input--style-5" minlength="6" #passwordE="ngModel"  type="password" name="password"
                       [class.form-control-danger]="!passwordE.valid && !passwordE.untouched">
                <label id="pass-error" class="error mt-2 text-danger" for="pass" *ngIf="!passwordE.valid && !passwordE.untouched">Мінімальна довжина 6 символів</label>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="name">Контактний номер</div>
            <div class="value">
              <div class="input-group-desc">
                <input id="tel" [(ngModel)]="telephone" class="input--style-5" minlength="16" #tel="ngModel" type="text" name="phone"
                       prefix="+" mask="00 000 00 00 000">
                <label class="label--desc">+38 098 00 00 000</label>
                <label id="tel-error" class="error mt-2 text-danger" for="tel"  [hidden]="tel.valid || tel.untouched"></label>
              </div>
            </div>


          </div>
          <div class="form-row">
            <div class="name">Місто</div>
            <div class="value">
              <div class="input-group">
                <input [(ngModel)]="city" class="input--style-5" type="city" name="city">
              </div>
            </div>


          </div>
          <div class="form-row">
            <div class="name">Парафія</div>
            <div class="value">
              <div class="input-group">
                <input [(ngModel)]="paroch"  class="input--style-5" type="text" name="paroch">
              </div>
            </div>


          </div>
          <div class="form-row p-t-20">
            <label class="label label--block">Чи погоджуєшся на зберігання і обробку твоїх персональних даних?</label>
            <div class="p-t-15 d-flex flex-wrap"  >

              <mat-radio-group>

                  <div class="col-1">
                    <mat-radio-button  value="1" (change)="isAgree= true">Так</mat-radio-button></div>
                <div class="col-2"><mat-radio-button  value="2" (change)="isAgree=false">Ні</mat-radio-button></div>
              </mat-radio-group>

            </div>
          </div>
          <div class="align-content-center align-items-center">
            <button class="btn btn--radius-2 btn--green" type="submit" (click)="addUsers()" [@inOutAnimation] *ngIf="isAgree">Зареєструватись</button>
            <span [@inOutAnimation] *ngIf="!isAgree" >Погоджуєшся?</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
