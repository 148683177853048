<div *ngIf="user.team" class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <div class="d-block d-sm-block d-md-none"><h3>{{user.team ? user.team.title : 'Назва відсутня'}}</h3>
              <h5 class="mb-0 mr-2 text-muted">{{user.team ? user.team.city : 'Місто не вказане'}}</h5></div>
            <div class="border-bottom text-center pb-4">
              <img src="{{user.team ? user.team.image : 'assets/img/no-team.png'}}" alt="profile"
                   class="img-lg rounded-circle mb-3">
              <p>{{user.team ? user.team.about : 'Опис відсутній'}}</p>
              <div class="d-flex justify-content-between">
              </div>
            </div>
            <div class="border-bottom py-4">
              <p>Титули</p>
              <div>
                <label class="badge badge-outline-dark">Перші завойовники</label>
              </div>
            </div>
            <div class="border-bottom py-4">

            </div>
            <div class="py-4">
              <p class="clearfix">
                <span class="float-left"> Капітан </span>
                <span class="float-right "
                      [class.text-muted]="!user.team.captain"> {{user.team.captain ? user.team.captain.name : 'Відсутній'}} </span>
              </p>
              <p class="clearfix">
                <span class="float-left"> Статус </span>
                <span class="float-right " [class.text-muted]="!user.team.captain"> Active </span>
              </p>
              <p class="clearfix">
                <span class="float-left"> Телефон </span>
                <span class=" float-right "
                      [class.text-muted]="!user.team.captain"> {{user.team.captain ? user.team.captain.telephone : 'Відсутній'}}  </span>
              </p>
              <p class="clearfix">
                <span class="float-left"> Е-Mail </span>
                <span class="float-right "
                      [class.text-muted]="!user.team.captain"> {{user.team.captain ? user.team.captain.email : 'Відсутній'}}  </span>
              </p>
              <p class="clearfix">
                <span class="float-left"> Парафія старшого </span>
                <span class="float-right " [class.text-muted]="!user.team.captain">
                              <a href="#">{{user.team.captain ? user.team.captain.paroch : 'Відсутній'}} </a>
                            </span>
              </p>

            </div>
            <button  *ngIf="isItCaptain" (click)="changeCaptain()" class="btn btn-gradient-primary mb-2 btn-block">Змінити капітана</button>
          </div>
          <div class="col-lg-8">
            <div class="d-flex justify-content-between">
              <div class="d-none d-sm-block">
                <h3 >{{user.team ? user.team.title : 'Назва відсутня'}}</h3>
                <div class="d-flex align-items-center">
                  <h5 class="mb-0 mr-2 text-muted">{{user.team ? user.team.city : 'Місто не вказане'}}</h5>
                  <div  class="br-wrapper ml-4 br-theme-css-stars"><select id="profile-rating" name="rating"
                                                                     autocomplete="off" style="display: none;">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                    <div (mouseout)="intialRating()" class="br-widget"><a   (click)="selected = 1" [class.br-active]="sel1" (mouseover)="mouseOverUse(1)" href="#"
                                              data-rating-value="1" data-rating-text="1" ></a>
                      <a href="#" data-rating-value="2" (click)="selected = 2" [class.br-active]="sel2"  (mouseover)="mouseOverUse(2)"
                         data-rating-text="2"></a>
                      <a href="#" data-rating-value="3" (click)="selected = 3" [class.br-active]="sel3" (mouseover)="mouseOverUse(3)"
                         data-rating-text="3"></a>
                      <a href="#" data-rating-value="4" (click)="selected = 4" [class.br-active]="sel4" (mouseover)="mouseOverUse(4)"
                         data-rating-text="4"></a>
                      <a href="#" data-rating-value="5" (click)="selected = 5" [class.br-active]="sel5" (mouseover)="mouseOverUse(5)"
                         data-rating-text="5"></a></div>
                  </div>
                </div>
              </div>
              <div class="text-center">

                <button (click)="leaveTeam()" class="btn btn-outline-secondary btn-icon">
                  <i class="mdi mdi-location-exit"></i>
                </button>
                <div class="mb-2 d-block d-sm-block d-md-none"></div>
                <button *ngIf="isItCaptain"  class="btn ml-2 btn-gradient-primary" (click)="openDialog()"> Добавити учасника </button>
              </div>
            </div>
            <div class="mt-4 mb-3 py-2 border-top text-center border-bottom align-items-center align-content-center">
              <span class="align-items-center">Учасники команди</span>
            </div>
            <div class="profile-feed">
              <div *ngFor="let userprofile of users, let i = index" class="d-flex align-items-start profile-feed-item">
                <img src="{{userprofile.logo && userprofile.logo !== '' ? userprofile.logo : 'assets/img/no-ava.png'}}" alt="profile" class="img-sm rounded-circle">
                <div class="ml-4">
                  <h6> {{userprofile.name}} <small class="ml-4 text-muted"><i class="fas fa-cross mr-1"></i>{{userprofile.paroch}}</small>
                  </h6>
                  <p><i class="mdi mdi-city mr-1"></i> {{userprofile.city}}  <button *ngIf="isItCaptain" (click)="kickFromTeam(i, true)" type="button" class="btn btn-outline-secondary btnteam btn-icon">
                  <i class="mdi  mdi-location-exit"></i> </button> </p>
                </div>
              </div>
              <div *ngFor="let userprofile of textUsers, let i = index" class="d-flex align-items-start profile-feed-item">
                <img src="assets/img/no-ava.png" alt="profile" class="img-sm mb-4 rounded-circle">
                <div class="ml-4">
                  <h6> {{userprofile.name}}</h6>
                  <p> <button *ngIf="isItCaptain" (click)="kickFromTeam(i, false)" type="button" class="btn btn-outline-secondary btnteam btn-icon">
                    <i class="mdi  mdi-location-exit"></i>
                  </button></p>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 <div *ngIf="!user.team" class="d-flex flex-column justify-content-center align-items-center" style="height: 500px">
   <h3 class="mb-4">Команда відсутня!</h3>
   <p><button class="btn btn-gradient-primary " (click)="switchPage('TeamCreate')">Створити команду</button></p>
 </div>




